import { Controller } from "@hotwired/stimulus";

// Connects to data-controller='admin-textures-popup'
export default class extends Controller {
  static targets = ["popup"];

  open() {
    if (this.hasPopupTarget) {
      this.popupTarget.style.display = "block";
    }
  }

  close() {
    if (this.hasPopupTarget) {
      this.popupTarget.style.display = "none";
    }
  }
}
