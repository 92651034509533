import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false
Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

import "./controllers"

require('./packs/theme')
require('./packs/header')
require('./packs/home_slider')
require('./packs/anchors')
