import $ from 'jquery';


const html = $('html');
const nightDayMode = $('.night-day__mode');

const savedTheme = sessionStorage.getItem('theme') || 'light';
html.attr('data-theme', savedTheme);
nightDayMode.toggleClass('dark', savedTheme === 'dark').toggleClass('light', savedTheme === 'light');

nightDayMode.on('click', function () {
  const isDark = html.attr('data-theme') === 'dark';
  const newTheme = isDark ? 'light' : 'dark';

  html.attr('data-theme', newTheme);
  nightDayMode.toggleClass('dark', !isDark).toggleClass('light', isDark);
  sessionStorage.setItem('theme', newTheme);

  $.post('/set_theme', JSON.stringify({ theme: newTheme }), null, 'json')
    .fail(error => console.error('Fetch error:', error));
});
