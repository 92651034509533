import { Controller } from "@hotwired/stimulus";

// Connects to data-controller='admin-tiles-product-name-setter'
export default class extends Controller {
  static targets = ["nameInput", "materialType", "collectionName", "collectionFormat"];

  changeNameInput() {
    const materialTypeText = this.materialTypeTarget.options[this.materialTypeTarget.selectedIndex].text;
    const collectionNameText = this.collectionNameTarget.options[this.collectionNameTarget.selectedIndex].text;
    const collectionFormatText = this.collectionFormatTarget.options[this.collectionFormatTarget.selectedIndex].text;

    this.nameInputTarget.value = `${materialTypeText} ${collectionNameText} ${collectionFormatText}`;
  }
}
