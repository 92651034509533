import Swiper from 'swiper';
import { Pagination, Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css';

import $ from 'jquery';

$(document).on('turbo:load', function () {
  var myMainSwiper = new Swiper('.main-slider', {
    modules: [Pagination, Autoplay, EffectFade],
    loop: true,
    effect: "fade",
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    autoplay: {
      delay: 8000,
      disableOnInteraction: false
    },
  });

  document.querySelectorAll('.swiper-pagination-bullet').forEach((bullet, index) => {
    bullet.addEventListener('mouseover', () => {
      myMainSwiper.slideToLoop(index);
    });
  });
});
