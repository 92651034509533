import $ from 'jquery';

$(document).on('turbo:load', function () {
  if ($('.burger-btn').length) {
    $('.burger-btn').on('click', function () {
      $(this).toggleClass('active');

      $('.header-adaptive').toggleClass('active');

      // if ($(window).width() < 601) {
      //   if ($('.header-adaptive').hasClass('active')) {
      //     $('body').css('overflow', 'hidden');
      //   } else {
      //     $('body').css('overflow', '');
      //   }
      // }
    });
  }
});

$(document).on('turbo:load', function () {
  let lastScrollTop = 0;
  const $header = $('#header-adaptive');

  if ($header.length) {
    $(window).on('scroll', function () {
      const currentScrollTop = $(window).scrollTop();
      const windowWidth = $(window).width();

      if (windowWidth < 1025 && windowWidth > 601) {
        if (currentScrollTop > lastScrollTop) {
          $header.removeClass('down');
        } else {
          $header.addClass('down');
        }

        if (currentScrollTop < 50) {
          $header.addClass('down');
        }
      }

      lastScrollTop = currentScrollTop;
    });
  }
});

$(document).on('turbo:load', function () {
  let $popupOverlay = $('.compare-box-overlay');
  let $closeBtn = $('.close-compare-box');
  let $openPopupBtns = $('.compare-box-open');

  if ($popupOverlay.length) {
    function openPopup() {
      $popupOverlay.css('visibility', 'visible');
      $('body').css('overflow', 'hidden');
    }

    function closePopup() {
      $popupOverlay.css('visibility', 'hidden');
      $('body').css('overflow', '');
    }

    $closeBtn.on('click', closePopup);

    $popupOverlay.on('click', function (event) {
      if ($(event.target).is($popupOverlay)) {
        closePopup();
      }
    });

    $openPopupBtns.each(function () {
      $(this).on('click', openPopup);
    });
  }
});

$(document).on('turbo:load', function () {
  $('#welcome-btn').on('click', function () {
    $('.header-adaptive').removeClass('active');
    $('.burger-btn').removeClass('active');
    $('body').css('overflow', '');
  });
});
